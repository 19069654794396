<template>
  <BaseDialog
    title="獎項編輯"
    :btn1Loading="loading"
    width="610px"
    @cancel="$emit('close')"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem label="獎勵類型">
        {{ displayData.displayRewardType }}
      </BaseElFormItem>
      <BaseElFormItem
        v-if="['point', 'cashback'].includes(displayData.rewardType)"
        :label="displayData.displayRewardType"
        prop="awardAmount"
      >
        {{ displayData.awardAmount }}
      </BaseElFormItem>

      <BaseElFormItem
        v-if="displayData.rewardType === 'coupon' || displayData.rewardType === 'classticket'"
        label="票券綁定"
        prop="couponId"
      >
        <div v-if="displayData.rewardType === 'clasTicket'">
          {{ formData.classTicket?.name }}
        </div>
        <div v-if="displayData.rewardType === 'coupon'">
          {{ formData.coupon?.name }}
        </div>
        <div v-if="displayData.awardIsNotAvailable">
          票券已失效
        </div>

        <!-- <p v-if="formData.status ==='notExist'" class="danger">找不到先前綁定的票券，請重新綁定票券</p> -->
        <div v-if="formData.coupon?.type === 'exchange'">
          <div v-if="displayData.awardErrorStatus === awardTypeConfig.noStock.value">
            <p class="text-danger">庫存數量：0</p>
            <p class="text-gray-60">此票券為「兌換券」，請留意兌換碼剩餘數量。相關設定請至「票券模組 > 兌換券設定 > 兌換碼管理」查看</p>
          </div>
          <div v-if="displayData.awardErrorStatus === awardTypeConfig.notEnoughStock.value">
            <p class="text-primary-100">庫存數量：{{ couponStock() }}</p>
            <p class="text-gray-60">此票券為「兌換券」，請留意兌換碼剩餘數量。相關設定請至「票券模組 > 兌換券設定 > 兌換碼管理」查看</p>
          </div>
        </div>
      </BaseElFormItem>

      <BaseElFormItem label="獎項數量" prop="availableStock">
        <div>
          <BaseElInput
            v-model="formData.availableStock"
            testName="formData_availableStock"
            :disabled="formData.unlimitedStock"
            type="number"
            :min="0"
            :max="defaultRangeMax"
            :placeholder="`請輸入數字0~${defaultRangeMax}`"
          />
          <!-- <p v-if="showCouponExchange && (formData.status === 'noStock' || formData.status === 'notEnoughStock') && couponStock(formData.awardCouponId) < formData.lastStock" class="warning">庫存數量不足，建議補足庫存 {{ ((formData.lastStock) - couponStock(formData.awardCouponId)) || '-' }} 件 (還需送出 {{ formData.lastStock }} 份獎項)</p> -->

          <div>
            <BaseElCheckbox
              v-if="formData.type !== 'miss'"
              v-model="formData.showAvailableStock"
              testName="formData_showAvailableStock"
            >
              前台顯示數量
            </BaseElCheckbox>
            <BaseElCheckbox
              v-if="formData.type !== 'miss'"
              v-model="formData.unlimitedStock"
              testName="formData_unlimitedStock"
              @change="() => formData.availableStock = null"
            >
              無數量上限
            </BaseElCheckbox>
          </div>
        </div>
      </BaseElFormItem>

      <BaseElFormItem label="獎項名稱">
        {{ displayData.awardName }}
      </BaseElFormItem>

      <BaseElFormItem label="圖片" prop="img">
        <UploadButton
          :img="formData.img"
          :isAvatar="true"
          @change="loadImg"
        />
      </BaseElFormItem>
    </BaseElForm>

    <ImageCropper
      v-if="uploadDialog"
      :image="imagePlaceHolder"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { get, toNumber } from 'lodash'
import { RewardTypesShowConfig } from '@/config/lottery'
import { UpdateRewardDistribution } from '@/api/rewardDistribution'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { FindClassTicket } from '@/api/classTicket'
import { FindCoupon } from '@/api/lottery/coupon'
import { awardTypeConfig } from '@/config/rewardDistribution'
export default defineComponent({
  components: {
    BaseDialog,
    UploadButton,
    ImageCropper,
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { formRef, formData, loading, initFormData, checkForm } = useBaseForm()
    const uploadDialog = ref(false)
    const imagePlaceHolder = ref(null)
    const defaultRangeMax = 999999
    initFormData({
      availableStock: null,
      showAvailableStock: false,
      unlimitedStock: false,
      img: null,
      awardAmount: null,
      couponId: null,
      classticketId: null,
      classTicket: null,
      coupon: null,
    })

    const formRules = computed(() => {
      const rules = {}
      return rules
    })

    const displayData = computed(() => {
      const type = get(props.configData, 'award.StandardAward.type')
      const awardError = get(props.configData, 'award.errors[0]')
      let awardIsNotAvailable = false

      if (get(awardError, 'type') === 'notExist') {
        awardIsNotAvailable = true
      }

      return {
        awardName: get(props.configData, 'awardName', '-'),
        displayRewardType: get(RewardTypesShowConfig, `${type}.name`, '-'),
        rewardType: type,
        awardAmount: get(props.configData, 'award.StandardAward.amount', '-'),
        awardIsNotAvailable,
        awardErrorStatus: awardError?.type,
      }
    })

    const syncFormData = async () => {
      const awardData = get(props.configData, 'award.StandardAward')
      const awardType = get(awardData, 'type')
      formData.availableStock = get(props.configData, 'availableStock', null)
      formData.showAvailableStock = get(props.configData, 'showAvailableStock', null)
      formData.unlimitedStock = get(props.configData, 'unlimitedStock', null)
      formData.couponId = get(props.configData, 'award.StandardAward.CouponId', null)
      formData.classticketId = get(props.configData, 'award.StandardAward.ClassTicketId', null)

      if (formData.unlimitedStock) formData.availableStock = undefined

      formData.img = get(awardData, 'Image', null)
      formData.awardAmount = get(awardData, 'amount', null)

      if (awardType === 'coupon') {
        const [res, err] = await FindCoupon({
          shopId: shopId.value,
          id: formData.couponId,
        })
        formData.coupon = res
      } else if (awardType === 'classticket') {
        const [res, err] = await FindClassTicket({
          shopId: shopId.value,
          id: formData.classticketId,
        })
        formData.classTicket = res
      }
    }

    const loadImg = (img) => {
      imagePlaceHolder.value = img
      uploadDialog.value = true
    }

    const getImage = (data) => {
      console.log(getImage)
      formData.img = data
      uploadDialog.value = false
    }

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      if (!(await checkForm(formRef.value))) {
        loading.value = false
        return
      }
      const payload = {
        shopId: shopId.value,
        id: get(props.configData, 'id'),
        startAt: get(props.configData, 'startAt'),
        endAt: get(props.configData, 'endAt'),
        description: get(props.configData, 'description'),
        notice: get(props.configData, 'notice'),
        showAvailableStock: formData.showAvailableStock,
        availableStock: toNumber(formData.availableStock),
        unlimitedStock: formData.unlimitedStock,
        // awardImageId: get(formData.img, 'id'),
      }
      const [, err] = await UpdateRewardDistribution(payload)
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }

    const couponStock = () => {
      const item = formData.coupon
      return get(item, 'sentLimit') - get(item, 'sentCount')
    }

    watch(() => props.selectRow, () => {
      syncFormData()
    })

    onMounted(() => {
      syncFormData()
    })

    return {
      formRef,
      formData,
      formRules,
      loading,
      initFormData,
      displayData,
      defaultRangeMax,
      uploadDialog,
      imagePlaceHolder,
      loadImg,
      getImage,
      onConfirm,
      awardTypeConfig,
      couponStock,
    }
  },
})

</script>

<style scoped lang="postcss">

</style>
